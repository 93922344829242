.banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 20px;
}

label {
  margin-bottom: 5px;
}

select,
input[type="file"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 10px;
}

button {
  padding: 10px 20px;
  background-color: #f58634;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.banner-container > div {
  margin-bottom: 15px;
}

.banner-container img {
  max-width: 100%;
  max-height: 200px;
  margin-top: 10px;
}
.theimagepreview {
  display: flex;
  flex-direction: column;
}
.theimagepreview2 {
  display: flex;
  align-items: center;
}
.theimagepreview2 > img {
  width: 250px;
  margin-right: 5%;
}
