/* brandsmanagement.css */

/* Main container */
.brands-management {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}

/* Add brand section */
.add-brand {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.add-brand input[type="text"] {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.theaddbrandbutton {
  padding: 8px 16px;
  background-color: #f58634;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
  align-self: center;
  margin-bottom: 3%;
}

.theaddbrandbutton:hover {
  background-color: #0056b3;
}

/* Brands list */
.brands-list {
  width: 300px; /* Adjust width as needed */
}

.brand-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.brand-item span {
  font-size: 16px;
}

.delete-icon {
  cursor: pointer;
  color: #dc3545;
}

.delete-icon:hover {
  color: #c82333;
}
.no-brands-message {
  font-size: 16px;
  color: #555;
  margin-top: 20px;
}
