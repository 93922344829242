.coupons-container {
  padding: 20px;
  margin-left: 20%;
  margin-top: 5%;
}

.coupons-list {
  margin-top: 20px;
}

.coupon-item {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  overflow: scroll;
  justify-content: space-between;
}
.coupons-list-header {
  display: flex;

  padding: 20px;

  width: 100%;
  margin-top: 2%;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #333;
}

.coupon-field {
  flex: 1;
  text-align: center;
}
.delete-confirmation-modal,
.add-coupon-modal,
.edit-coupon-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 15%;
}

.modal-content input {
  width: 100%;
  margin-bottom: 10px;
}

.modal-content button {
  padding: 10px 20px;

  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.modal-content button:last-child {
  background-color: #dc3545;
}

.modal-content button:hover {
  background-color: #0056b3;
}

.modal-content button:last-child:hover {
  background-color: #c82333;
}
.delete-icon,
.edit-icon {
  cursor: pointer;
  margin-left: 10px;
}

.delete-icon svg {
  width: 20px;
  height: 20px;
}
